//import and export judge image using this

import Wallace from  "../../Assets/Judges/Wallace.jpg";
import Robert from  "../../Assets/Judges/robert.jpg";
import Jackie from  "../../Assets/Judges/jackie.jpg";
import Jack from  "../../Assets/Judges/jack.jpg";
import Jessie from  "../../Assets/Judges/jessie.jpg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Wallace,
    Robert,
    Jackie,
    Jack,
    Jessie,
}